var render = function () {
  var _vm$paymentDictionari, _vm$productSelects;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['marketplace-filters filters-container table filters-blank', _vm.toggleFilters ? 'marketplace-filters--hidden' : '']
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_vm.type === 'subscriptions' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['code', 'sourceName']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'New',
        class: 'success',
        value: 'new'
      }, {
        label: 'Authorized',
        class: 'lead',
        value: 'passed'
      }, {
        label: 'Cancelled',
        class: 'danger',
        value: 'cancelled'
      }],
      "componentId": "campaignStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Registration status",
      "options": [{
        label: 'Registered',
        value: 'registered'
      }, {
        label: 'Unregistered',
        value: 'unregistered'
      }],
      "componentId": "registrationStatus",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.registrationStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationStatus, "value", $$v);
      },
      expression: "filters.registrationStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Agreement date",
      "componentId": "agreementDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.agreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.agreementDate, "value", $$v);
      },
      expression: "filters.agreementDate.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Investment reach",
      "componentId": "investmentReach",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentReach.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentReach, "value", $$v);
      },
      expression: "filters.investmentReach.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Capital value",
      "componentId": "capitalValue",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.capitalValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.capitalValue, "value", $$v);
      },
      expression: "filters.capitalValue.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Series of shares",
      "isDisabled": true,
      "componentId": "seriesOfShares",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'templates' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['authorName', 'title']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'DRAFT',
        class: 'success',
        value: 'DRAFT'
      }, {
        label: 'ACTIVE',
        class: 'primary',
        value: 'ACTIVE'
      }, {
        label: 'ARCHIVED',
        class: 'lead',
        value: 'ARCHIVED'
      }],
      "componentId": "investorStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Type",
      "options": _vm.typeOptions,
      "componentId": "isAttachment",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.isAttachment.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.isAttachment, "value", $$v);
      },
      expression: "filters.isAttachment.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Created",
      "componentId": "createdAt",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Edited",
      "componentId": "updatedAt",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.updatedAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.updatedAt, "value", $$v);
      },
      expression: "filters.updatedAt.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Autenti",
      "options": _vm.autentiOptions,
      "isDisabled": !_vm.hasAutenti,
      "componentId": "autentiEnabled",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.autentiEnabled.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.autentiEnabled, "value", $$v);
      },
      expression: "filters.autentiEnabled.value"
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'advisors' && !_vm.hasIssuerId ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['firstName', 'surname', 'email', 'identificationNumber']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'Initial',
        class: 'success',
        value: 'initial'
      }, {
        label: 'Active',
        class: 'primary',
        value: 'active'
      }, {
        label: 'Deleted',
        class: 'danger',
        value: 'deleted'
      }],
      "componentId": "investorStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Network",
      "options": _vm.networkOptions,
      "componentId": "brokerNetworkIds",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.brokerNetworkIds.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.brokerNetworkIds, "value", $$v);
      },
      expression: "filters.brokerNetworkIds.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Last login",
      "componentId": "lastLogin",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.lastLogin.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.lastLogin, "value", $$v);
      },
      expression: "filters.lastLogin.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Start of POA",
      "componentId": "startPowerOfAttorney",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.startPowerOfAttorney.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.startPowerOfAttorney, "value", $$v);
      },
      expression: "filters.startPowerOfAttorney.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "End of POA",
      "componentId": "endPowerOfAttorney",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.endPowerOfAttorney.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.endPowerOfAttorney, "value", $$v);
      },
      expression: "filters.endPowerOfAttorney.value"
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'campaigns' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['campaignName']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'Draft',
        class: 'success',
        value: 'draft'
      }, {
        label: 'Active',
        class: 'primary',
        value: 'active'
      }, {
        label: 'Finished',
        class: 'disabled',
        value: 'finished'
      }, {
        label: 'Archived',
        class: 'danger',
        value: 'archived'
      }],
      "componentId": "campaignStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Start date",
      "componentId": "startDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.startDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.startDate, "value", $$v);
      },
      expression: "filters.startDate.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "End date",
      "componentId": "endDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.endDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.endDate, "value", $$v);
      },
      expression: "filters.endDate.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Investment value",
      "componentId": "investmentValue",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentValue, "value", $$v);
      },
      expression: "filters.investmentValue.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Category",
      "isDisabled": true,
      "componentId": "category",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Type",
      "isDisabled": true,
      "componentId": "type",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Interest P.A.",
      "isDisabled": true,
      "componentId": "interestPa",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'kyc' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['investorEmail', 'brokerName']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Created date",
      "componentId": "createdAt",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'Sent',
        class: 'lead',
        value: 'sent'
      }, {
        label: 'Viewed',
        class: 'success',
        value: 'viewed'
      }, {
        label: 'Submitted',
        class: 'primary',
        value: 'submitted'
      }],
      "componentId": "status",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  })], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'payments' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['campaignName', 'productCode', 'investmentClient', 'bankAccount']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'Paid',
        class: 'primary',
        value: 'paid'
      }, {
        label: 'Unpaid',
        class: 'danger',
        value: 'unpaid'
      }, {
        label: 'Scheduled',
        class: 'success',
        value: 'scheduled'
      }, {
        label: 'Cancelled',
        class: 'danger',
        value: 'cancelled'
      }, {
        label: 'Partially Paid',
        class: 'lead',
        value: 'partially paid'
      }],
      "componentId": "paymentStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentStatus, "value", $$v);
      },
      expression: "filters.paymentStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Product type",
      "options": _vm.productSelects.productType,
      "componentId": "productType",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productType, "value", $$v);
      },
      expression: "filters.productType.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Currency",
      "options": _vm.usedCurrencies,
      "componentId": "currency",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.currency.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.currency, "value", $$v);
      },
      expression: "filters.currency.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Agreement date",
      "componentId": "agreementDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.agreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.agreementDate, "value", $$v);
      },
      expression: "filters.agreementDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Outflow date",
      "componentId": "repaymentDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.repaymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentDate, "value", $$v);
      },
      expression: "filters.repaymentDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Payment type",
      "options": ((_vm$paymentDictionari = _vm.paymentDictionaries) === null || _vm$paymentDictionari === void 0 ? void 0 : _vm$paymentDictionari.paymentType) || [],
      "componentId": "paymentType",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentType, "value", $$v);
      },
      expression: "filters.paymentType.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Date from",
      "componentId": "interestPeriodFrom",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestPeriodFrom.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodFrom, "value", $$v);
      },
      expression: "filters.interestPeriodFrom.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Date to",
      "componentId": "interestPeriodTo",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodTo, "value", $$v);
      },
      expression: "filters.interestPeriodTo.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Tax value",
      "componentId": "interestTax",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestTax.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestTax, "value", $$v);
      },
      expression: "filters.interestTax.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Net value",
      "componentId": "interestAmountNet",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestAmountNet.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestAmountNet, "value", $$v);
      },
      expression: "filters.interestAmountNet.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Gross value",
      "componentId": "interestAmountGross",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestAmountGross.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestAmountGross, "value", $$v);
      },
      expression: "filters.interestAmountGross.value"
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'clatTax' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['productCode', 'investor']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": [{
        label: 'Paid',
        class: 'primary',
        value: 'paid'
      }, {
        label: 'Unpaid',
        class: 'danger',
        value: 'unpaid'
      }, {
        label: 'Scheduled',
        class: 'success',
        value: 'scheduled'
      }, {
        label: 'Cancelled',
        class: 'danger',
        value: 'cancelled'
      }, {
        label: 'Partially Paid',
        class: 'lead',
        value: 'partially paid'
      }],
      "componentId": "paymentStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentStatus, "value", $$v);
      },
      expression: "filters.paymentStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Product type",
      "options": _vm.productSelects.productType,
      "componentId": "productType",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productType, "value", $$v);
      },
      expression: "filters.productType.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Statement generated",
      "options": [{
        label: 'YES',
        value: '1'
      }, {
        label: 'NO',
        value: '0'
      }],
      "componentId": "statementGenerated",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.statementGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.statementGenerated, "value", $$v);
      },
      expression: "filters.statementGenerated.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Payment date",
      "componentId": "paymentDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDate, "value", $$v);
      },
      expression: "filters.paymentDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Payment deadline",
      "componentId": "paymentDeadline",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentDeadline.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDeadline, "value", $$v);
      },
      expression: "filters.paymentDeadline.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Value",
      "componentId": "value",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.value.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.value, "value", $$v);
      },
      expression: "filters.value.value"
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type === 'products' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['investmentClientName', 'productCode']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": _vm.productStatuses,
      "componentId": "productStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productStatus, "value", $$v);
      },
      expression: "filters.productStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Source",
      "options": _vm.formattedProductSources,
      "componentId": "productSource",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productSourceId.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productSourceId, "value", $$v);
      },
      expression: "filters.productSourceId.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Class",
      "options": (_vm$productSelects = _vm.productSelects) === null || _vm$productSelects === void 0 ? void 0 : _vm$productSelects.class,
      "componentId": "productClass",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productClass.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productClass, "value", $$v);
      },
      expression: "filters.productClass.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Investor source",
      "options": _vm.formattedInvestorSources,
      "componentId": "investorSource",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentClientSourceId.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientSourceId, "value", $$v);
      },
      expression: "filters.investmentClientSourceId.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Agreement date",
      "componentId": "agreementDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productAgreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productAgreementDate, "value", $$v);
      },
      expression: "filters.productAgreementDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Investor status",
      "options": _vm.investorStatuses,
      "componentId": "investorStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentClientStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientStatus, "value", $$v);
      },
      expression: "filters.investmentClientStatus.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Inflow date",
      "componentId": "inflowDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDate, "value", $$v);
      },
      expression: "filters.paymentDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Outflow date",
      "componentId": "outflowDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.interestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodTo, "value", $$v);
      },
      expression: "filters.interestPeriodTo.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Instrument value",
      "componentId": "instrumentValue",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productValue, "value", $$v);
      },
      expression: "filters.productValue.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Currency",
      "options": _vm.usedCurrencies,
      "componentId": "currency",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productCurrency.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCurrency, "value", $$v);
      },
      expression: "filters.productCurrency.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Schedule status",
      "options": [{
        label: 'Generated',
        value: '1'
      }, {
        label: 'Not generated',
        value: '0'
      }],
      "componentId": "scheduleStatus",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.repaymentScheduleGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentScheduleGenerated, "value", $$v);
      },
      expression: "filters.repaymentScheduleGenerated.value"
    }
  }) : _vm._e(), _vm.hasAutenti ? [_vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "E-signature status",
      "options": _vm.autentiStatuses,
      "componentId": "eSignatureStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.autentiStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.autentiStatus, "value", $$v);
      },
      expression: "filters.autentiStatus.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Signed status",
      "options": _vm.signedStatuses,
      "componentId": "signedStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.signedStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.signedStatus, "value", $$v);
      },
      expression: "filters.signedStatus.value"
    }
  }) : _vm._e()] : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 2), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e(), _vm.type !== 'templates' && _vm.type !== 'campaigns' && _vm.type !== 'advisors' && _vm.type !== 'payments' && _vm.type !== 'clatTax' && _vm.type !== 'subscriptions' && _vm.type !== 'products' && _vm.type !== 'kyc' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "marketplace-filters__container"
  }, [_c('div', {
    staticClass: "marketplace-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['investmentClientName', 'productCode']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Status",
      "options": _vm.investorStatuses,
      "componentId": "investorStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentClientStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientStatus, "value", $$v);
      },
      expression: "filters.investmentClientStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Source",
      "options": _vm.formattedInvestorSources,
      "componentId": "investorSource",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentClientSourceId.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientSourceId, "value", $$v);
      },
      expression: "filters.investmentClientSourceId.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Potential",
      "options": _vm.investorSelects.investmentPotentials,
      "componentId": "investorPotential",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.investmentClientInvestmentPotential.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientInvestmentPotential, "value", $$v);
      },
      expression: "filters.investmentClientInvestmentPotential.value"
    }
  }), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Product class",
      "options": _vm.productSelects.class,
      "componentId": "productClass",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productClass.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productClass, "value", $$v);
      },
      expression: "filters.productClass.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Product source",
      "options": _vm.formattedProductSources,
      "componentId": "productSource",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productSourceId.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productSourceId, "value", $$v);
      },
      expression: "filters.productSourceId.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Agreement date",
      "componentId": "agreementDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productAgreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productAgreementDate, "value", $$v);
      },
      expression: "filters.productAgreementDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Inflow date",
      "componentId": "inflowDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productPaymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productPaymentDate, "value", $$v);
      },
      expression: "filters.productPaymentDate.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Outflow date",
      "componentId": "outflowDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productInterestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productInterestPeriodTo, "value", $$v);
      },
      expression: "filters.productInterestPeriodTo.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Instrument value",
      "componentId": "instrumentValue",
      "type": "range"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productValue, "value", $$v);
      },
      expression: "filters.productValue.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Product currency",
      "options": _vm.usedCurrencies,
      "componentId": "currency",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productCurrency.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCurrency, "value", $$v);
      },
      expression: "filters.productCurrency.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Product status",
      "options": _vm.productStatuses,
      "componentId": "productStatus",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.productStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productStatus, "value", $$v);
      },
      expression: "filters.productStatus.value"
    }
  }) : _vm._e(), _vm.filtersToggle ? _c('GenproxField', {
    attrs: {
      "label": "Schedule status",
      "options": [{
        label: 'Generated',
        value: '1'
      }, {
        label: 'Not generated',
        value: '0'
      }],
      "componentId": "scheduleStatus",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.repaymentScheduleGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentScheduleGenerated, "value", $$v);
      },
      expression: "filters.repaymentScheduleGenerated.value"
    }
  }) : _vm._e(), !_vm.filtersToggle ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "variant": "gray",
      "iconPosition": "right",
      "icon": _vm.EllipsisHorizontalIcon
    },
    on: {
      "click": function click($event) {
        _vm.filtersToggle = !_vm.filtersToggle;
      }
    }
  }, [_vm._v(_vm._s(!_vm.filtersToggle ? 'More' : 'Less'))]) : _vm._e()], 1), _c('div', {
    staticClass: "marketplace-filters__clear-wrapper"
  }, [_c('a', {
    class: ['marketplace-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }